@charset "UTF-8";
body { font-family: Arial, Helvetica, sans-serif, "Microsoft YaHei", "微软雅黑", SimSun, "宋体"; }

body.sdp-skin-creative div.portal-container, body.sdp-skin-creative div.default-header { max-width: 1000px; margin: 0 auto; }

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak { display: none !important; }

/*hide hate ad link for account pages*/
.hate-ad { display: none; }

.m-0 { margin: 0; }

/* Bootstrap Style */
.navbar {
  position: relative;
  min-height: 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
}
.navbar-fixed-bottom, .navbar-fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}
.navbar-default {
  background-color: #f8f8f8;
  border-color: #e7e7e7;
}
.navbar-fixed-top {
  top: 0;
  border-width: 0 0 1px;
}
.navbar-default .navbar-nav>li>a {
  color: #777;
}
.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
  color: #555;
  background-color: #e7e7e7;
}
@media (min-width: 768px) {
.navbar {
  border-radius: 4px;
}
.navbar-fixed-bottom, .navbar-fixed-top {
  border-radius: 0;
}
.navbar-nav>li>a {
  padding-top: 15px;
  padding-bottom: 15px;
} }
.navbar-nav>li>a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;
}
.nav>li>a {
  position: relative;
  display: block;
  padding: 10px 15px;
}

/* Portal Style */
.portal-container { margin: 0 auto; /** product */ /** partner */ }

.portal-container .terms { margin-bottom: 20px; }

.portal-container .terms .border-div { height: 1px; width: 100%; background: linear-gradient(to right, #e4e4e4, #fff); margin-bottom: 10px; }

.portal-container .terms p { margin-bottom: 30px; }

.portal-container .tdmove-job .banner { margin-bottom: 20px; }

.portal-container .tdmove-job .panel { border: 0px; box-shadow: none; border-bottom: 1px solid #ddd; border-radius: 0px; }

.portal-container .tdmove-job .panel .job-title { font-size: 18px; padding-bottom: 4px; font-weight: bold; margin: 0px; }

.portal-container .tdmove-job .panel .panel-heading { position: relative; }

.portal-container .tdmove-job .panel .panel-heading p { margin-bottom: 0px; }

.portal-container .tdmove-job .panel .panel-heading a { color: #333; text-decoration: none; }

.portal-container .tdmove-job .panel .panel-heading .rotate { -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */ -moz-transform: rotate(90deg); /* Firefox */ -ms-transform: rotate(90deg); /* IE 9 */ transform: rotate(90deg); /* Standard syntax */ }

.portal-container .tdmove-job .panel .panel-heading .btn { border-color: #337ab7; color: #2e6da4; background: none; position: absolute; right: 80px; top: 16px; }

.portal-container .tdmove-job .panel .panel-heading .right-btn { margin-top: 12px; }

.portal-container .tdmove-job .panel .panel-heading .right-btn p { font-size: 16px; margin-right: 180px; }

.portal-container .tdmove-job .panel .panel-heading .right-btn i { margin-top: 3px; }

.portal-container .tdmove-job .panel .panel-body { border-top: 0px; padding-bottom: 0px; }

.portal-container .tdmove-job .panel .panel-body ul { padding: 0px 0px 0px 20px; margin-bottom: 20px; }

.portal-container .tdmove-job .panel .panel-body ul h5 { margin-bottom: 12px; }

.portal-container .tdmove-job .panel .panel-body ul li { line-height: 28px; color: #243c52; }

.portal-container .aic-container { min-height: 1000px; }

.portal-container .overlay { position: fixed; display: none; width: 100%; height: 100%; top: 0; left: 0; right: 0; bottom: 0; background-color: rgba(0, 0, 0, 0.6); z-index: 1000; }

.portal-container .flipInY { visibility: hidden; }

.portal-container .bg-white { background-color: white; }

.portal-container .row { margin-right: -8px; margin-left: -8px; }

.portal-container .row .col-sm-12, .portal-container .row .col-sm-4, .portal-container .row .col-lg-4, .portal-container .row .col-xs-4 { padding-right: 8px; padding-left: 8px; }

.portal-container .container-8 { padding-left: 8px; padding-right: 8px; }

.portal-container .btn-outline { color: #19589b; background-color: transparent; border-color: #19589b; transition: all 0.5s ease; }

.portal-container .btn-outline:hover, .portal-container .btn-outline:focus, .portal-container .btn-outline:active { color: #fff; background-color: #19589b; border-color: #19589b; }

/*.portal-container .support-btn { background: url("/images/Support.png?v=219") no-repeat scroll 0% 0% #19589b; width: 40px; height: 124px; outline: medium none; position: fixed; z-index: 99999; right: -5px; text-indent: -99999px; top: 40%; }*/

.portal-container .everywhere .img-responsive, .portal-container .signupbox { display: inline-block; }

.portal-container #portalHead .default-header { background-color: #fff; border-color: #19589b; margin-bottom: 0; margin: 0 auto; padding: 0; }

.portal-container #portalHead .default-header .navbar-nav .nav-item + .nav-item { margin-left: 16px; }

.portal-container #portalHead .default-header.navbar-static-top, .portal-container #portalHead .default-header.navbar-fixed-top { border-width: 0 0 5px; }

.portal-container #portalHead .default-header .d-nav { float: left; margin: 0px; }

.portal-container #portalHead .default-header .d-nav > li { float: left; }

.portal-container #portalHead .default-header .d-nav > li > a { color: #989898; line-height: 60px; padding: 0 8px; }

.portal-container #portalHead .default-header .d-nav > li > a:hover { color: #333; }

.portal-container #portalHead .default-header .d-nav > li.active > a, .portal-container #portalHead .default-header .d-nav > li.active > a:focus { color: #333; border-bottom: #ed9118 5px solid; margin-bottom: -5px; }

.portal-container #portalHead .default-header .d-nav > li.active a, .portal-container #portalHead .default-header .d-nav > li.active a:hover, .portal-container #portalHead .default-header .d-nav > li.active a:focus { background: none; }

.portal-container #portalHead .default-header .btn-nav { margin-top: 12px; margin-right: 0; float: right; }

.portal-container #portalHead .default-header .btn-nav .or-and { color: #989898; margin: 0 15px; padding-top: 5px; display: inline-block; }

.portal-container #portalHead .default-header .btn-nav li { float: left; }

.portal-container #portalHead .default-header .default-logo { display: block; float: left; margin-right: 8px; }

.portal-container #portalHead .default-header .default-logo img { margin-top: 12px; height: 36px; }

.portal-container #portalHead .default-header .navbar-collapse:before, .portal-container #portalHead .default-header .navbar-collapse:after { content: " "; display: table; }

.portal-container #portalHead .default-header .navbar-collapse:after { clear: both; }

.portal-container #portalHead .default-header.navbar-sticky { min-height: 41px; border-bottom-width: 1px; border-color: #e1e1e1; background: rgba(255, 255, 255, 0.95); }

.portal-container #portalHead .default-header.navbar-sticky .d-nav { line-height: 16px; }

.portal-container #portalHead .default-header.navbar-sticky .d-nav > li a { line-height: 43px; }

.portal-container #portalHead .default-header.navbar-sticky .d-nav > li.active > a, .portal-container #portalHead .default-header.navbar-sticky .d-nav > li.active > a:focus { border-bottom-width: 2px; margin-bottom: -2px; }

.portal-container #portalHead .default-header.navbar-sticky .btn-nav { margin-top: 6px; }

.portal-container #portalHead .default-header.navbar-sticky .default-logo img { margin-top: 10px; height: 24px; }

.portal-container #portalHead .default-header.navbar-sticky .btn { padding: 4px 12px; font-size: 12px; }

.portal-container #portalHead .bw { -webkit-filter: grayscale(100%); filter: grayscale(100%); opacity: .3; }

.portal-container #portalHead .bw:hover { -webkit-filter: grayscale(0%); filter: grayscale(0%); -webkit-transition: all 1s ease; -moz-transition: all 1s ease; -o-transition: all 1s ease; -ms-transition: all 1s ease; transition: all 1s ease; opacity: 1; }

.portal-container #portalHead .btn { border-width: 2px; padding: 6px 16px; }

.portal-container #portalHead .btn.btn-outline { color: #ed9118; background-color: transparent; border-color: #ed9118; }

.portal-container #portalHead .btn.btn-outline:active, .portal-container #portalHead .btn.btn-outline:hover, .portal-container #portalHead .btn.btn-outline:focus { color: #fff; background-color: #ed9118; border-color: #ed9118; }

.portal-container #portalHead .btn.btn-signup { background-color: #ed9118; border-color: #ed9118; color: rgba(255, 255, 255, 0.75); transition: all 0.5s ease; }

.portal-container #portalHead .btn.btn-signup:hover { color: white; }

.portal-container .banner-with-video { text-align: center; position: relative; z-index: 100; padding-top: 65px; /*min-height: 175px;*/ }

.portal-container .aic-banner { background: #ed9118; padding: 20px 0px; }

.portal-container .aic-banner h1 { margin-top: 140px; color: #fff; }

.portal-container .aic-banner h3 { color: rgba(255, 255, 255, 0.5); }

.portal-container .area { position: relative; overflow: hidden; margin-top: -110px; padding-top: 110px; background-size: 100% auto; }

.portal-container .area .video-mask { position: absolute; top: 0; bottom: 0; min-width: 100%; min-height: 100%; width: auto; height: auto; z-index: 10; background: #2D578B; opacity: .5; }

.portal-container .area .video-preview { position: absolute; top: 0px; bottom: 0; left: 0; min-width: 100%; min-height: 100%; width: auto; height: auto; }

.portal-container .area .video-bg-area { position: relative; z-index: 100; }

.portal-container .area .video-bg-area .shadow-filter-img { /* For IE 8 */ filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=1, OffY=0,Color='#fff')"; /* chrome */ -webkit-filter: drop-shadow(1px 0px 0px white); /* others */ filter: drop-shadow(1px 0px 0px white); }

.portal-container .area .video-bg-area .what { text-align: center; padding: 20px 0px; }

.portal-container .area .video-bg-area .what h3 { font-size: 28px; }

.portal-container .area .video-bg-area .what .lead { font-size: 16px; color: #fff; font-family: Arial; margin-bottom: 0px; text-shadow: 0px 2px 4px black; }

.portal-container .area .video-bg-area .what .lead b { font-size: 24px; color: #ed9118; }

.portal-container .area .video-bg-area .what #what-list { overflow: hidden; }

.portal-container .area .video-bg-area .what #what-list h3 { color: #fff !important; font-weight: bold; margin-bottom: 0px; text-shadow: 0px 4px 4px black; }

.portal-container .area .video-bg-area .what #what-list .inner { background: none; position: relative; transform: translateY(550px); -moz-transform: translateY(550px); -webkit-transform: translateY(550px); }

.portal-container .area .video-bg-area .what #what-list .go-anim .inner { animation: column 300ms 1 normal ease-out forwards; -moz-animation: column 300ms 1 normal ease-out forwards; -webkit-animation: column 300ms 1 normal ease-out forwards; }

@keyframes column { from { transform: translateY(450px);
    opacity: 1; }
  to { transform: translateY(0);
    opacity: 1; } }

@-moz-keyframes column { from { -moz-transform: translateY(450px);
    opacity: 1; }
  to { -moz-transform: translateY(0);
    opacity: 1; } }

@-webkit-keyframes column { from { -webkit-transform: translateY(450px);
    opacity: 1; }
  to { -webkit-transform: translateY(0);
    opacity: 1; } }

.portal-container .im-mobile-app-download { text-align: center; padding: 5px 0px 5px 0; margin: 0px; background-color: #163B72; }

.portal-container .video { padding: 20px 0px; background: #ed9118; }

.portal-container .video .joinbox { background: #fff; border-radius: 4px; box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12); padding: 8px; }

.portal-container .video .joinbox h5 { margin: 8px 0 16px 0; color: #ed9118; font-weight: bold; font-size: 18px; }

.portal-container .video .joinbox .search_container { margin-bottom: 5px; }

.portal-container .video .joinbox .school-list-container { height: 251px; }

.portal-container .video .joinbox .school-list-container .nano .content { padding-right: 10px; }

.portal-container .video .joinbox .school-list-container .nano .slider { cursor: pointer; }

.portal-container .video .joinbox .school-list-container .default-school-list { padding: 0; }

.portal-container .video .joinbox .school-list-container .default-school-list li { list-style-type: none; padding: 8px 0 8px 36px; position: relative; border-bottom: #eceeef 1px solid; -webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; -ms-transition: all .5s ease; transition: all .5s ease; }

.portal-container .video .joinbox .school-list-container .default-school-list li a { color: #818a91; }

.portal-container .video .joinbox .school-list-container .default-school-list li:hover { cursor: pointer; border-bottom-color: #ed9118; }

.portal-container .video .joinbox .school-list-container .default-school-list li:hover a, .portal-container .video .joinbox .school-list-container .default-school-list li:hover a:focus { color: #ed9118; text-decoration: none; }

.portal-container .video .joinbox .school-list-container .default-school-list li .school-list-logo { position: absolute; left: 8px; width: 24px; height: 24px; border-radius: 100%; }

.portal-container .video .joinbox .school-list-container .default-school-list li .school-list-logo a { color: #818a91; }

.portal-container .video .partner-links { padding: 8px 16px; background: #fff; border-radius: 4px; margin: 0 auto; }

.portal-container .video #adsRight { min-height: 260px; display: inline-block; padding-bottom: 5px; }

.portal-container .why { background: #f5f5f5; padding: 40px 0; }

.portal-container .why h2 { margin-bottom: 40px; }

.portal-container .why p { font-size: 16px; }

.portal-container .why .col-sm-6 { padding: 20px; }

/*.portal-container .why .easy, .portal-container .why .free, .portal-container .why .support, .portal-container .why .fun { background: url(/images/default-icons.png?v=219) no-repeat; display: block; width: 75px; height: 75px; margin: 20px 20px 0 0; transition: all 0.1s linear; }*/

.portal-container .why .easy-area:hover, .portal-container .why .easy-area:hover p, .portal-container .why .free-area:hover, .portal-container .why .free-area:hover p, .portal-container .why .support-area:hover, .portal-container .why .support-area:hover p, .portal-container .why .fun-area:hover, .portal-container .why .fun-area:hover p { color: #2661a0; cursor: pointer; }

.portal-container .why .easy-area .easy { background-position: -627px 0; }

.portal-container .why .easy-area:hover .easy { background-position: -702px 0px; }

.portal-container .why .free-area .free { background-position: -629px -70px; }

.portal-container .why .free-area:hover .free { background-position: -704px -70px; }

.portal-container .why .support-area .support { background-position: -630px -227px; }

.portal-container .why .support-area:hover .support { background-position: -705px -227px; }

.portal-container .why .fun-area .fun { background-position: -632px -146px; }

.portal-container .why .fun-area:hover .fun { background-position: -707px -146px; }

.portal-container .aic-features { background: #fff; padding: 40px 0; }

.portal-container .aic-features .features-box { padding: 10px 0px; }

.portal-container .using-schools { /*background: url(/images/banner-logos.jpg?v=219) #225d9b repeat center center fixed; height: 560px;*/ text-align: center; padding: 40px 0; background: #19589b; }

.portal-container .using-schools h2 { color: #fff; margin-bottom: 40px; }

.portal-container .using-schools h2 span { color: #ed9118; }

.portal-container .using-schools h3 { color: #fff; }

.portal-container .using-schools p { font-size: 16px; color: rgba(255, 255, 255, 0.7); }

.portal-container .using-schools .lead { font-size: 30px; color: #fff; }

.portal-container .using-schools .sign-btn { margin-top: 50px; }

.portal-container .using-schools .btn-outline { color: #fff; background-color: transparent; border-color: #fff; padding: 15px 30px; }

.portal-container .using-schools .btn-outline:hover, .portal-container .using-schools .btn-outline:focus, .portal-container .using-schools .btn-outline:active { color: #225d9b; background-color: #fff; border-color: #225d9b; }

.portal-container .img-testimonials { padding: 40px 0px; }

.portal-container .img-testimonials .blockquote-reverse { border-right: 0px solid #eee; }

.portal-container .img-testimonials .blockquote-reverse h5:before { content: '\00A0 \2014'; }

.portal-container .img-testimonials blockquote { margin: 20px 0px; font-size: 16px; color: #333; border-left: 0px solid #eee; }

.portal-container .img-testimonials blockquote.pull-right { border-right: 0px solid #eee; }

.portal-container .take { background: #f5f5f5; padding: 40px 0px; text-align: center; position: relative; min-height: 400px; }

.portal-container .take .everywhere, .portal-container .take .take-btn { margin-top: 20px; margin-bottom: 20px; }

.portal-container .take .take-btn { width: 530px; margin: 0 auto; color: #626262; }

.portal-container .take .take-btn p { margin: 0px !important; height: 46px; }

/*.portal-container .take .take-btn a.desktop, .portal-container .take .take-btn a.tablet, .portal-container .take .take-btn a.appStore, .portal-container .take .take-btn a.googlePlay { background: url(/images/default-icons.png) no-repeat; width: 140px; color: #626262; text-align: center; display: inline-block; padding-top: 46px; padding-top: 46px; }*/

.portal-container .take .take-btn a.desktop { background-position: -100px 0; margin: 0px 25px; }

.portal-container .take .take-btn a.tablet { background-position: -285px 0px; margin: 0px 25px; }

.portal-container .take .take-btn a.appStore { background-position: -420px 0px; margin: 0px; width: 70px; }

.portal-container .take .take-btn a.googlePlay { background-position: -448px -72px; margin: 0px; width: 70px; }

.portal-container .take .take-btn a:hover { text-decoration: none; color: #333; }

.portal-container .take .take-btn a:hover.desktop { background-position: -100px -80px; }

.portal-container .take .take-btn a:hover.tablet { background-position: -285px -83px; }

.portal-container .take .take-btn a:hover.appStore { background-position: -420px -152px; }

.portal-container .take .take-btn a:hover.googlePlay { background-position: -448px -225px; }

.portal-container #footer .l-online { width: 210px; margin-top: 8px; }

.portal-container #footer .social { background: #333; color: #fff; padding: 20px 0px 20px 0px; }

.portal-container #footer .social a.email { color: #fff; }

.portal-container #footer .social .form-control[disabled] { background: #848484; border: #848484; }

.portal-container #footer .social .select { border: #666 solid 1px; background: #333; color: #ececec; }

.portal-container #footer .social .school-list li a { line-height: 24px; padding: 0px 10px; }

.portal-container #footer .social .mr-md { margin-right: 10px; }

.portal-container #footer .aic-reg { background: url(../img/brand/registerfooter.png) no-repeat center bottom #333; text-align: center; padding: 30px 0px; }

.portal-container #footer .aic-reg h2 { color: #fff; margin-bottom: 20px; }

.portal-container #footer .aic-reg .btn { width: 160px; height: 42px; background-color: #ec902d; padding-top: 10px; }

.portal-container #footer .contact-phone { font-size: 16px; }

.portal-container #footer .contact-phone .fa-1-5 { font-size: 24px; }

.portal-container #footer .online-chat a { color: #fff; text-decoration: none; font-size: 16px; padding-left: 26px; }

/*.portal-container #footer .l-online { background: url(/images/icons.png?v=219) no-repeat 0 0; background-position: -2px -6px; _background-position: -2px -3px; }*/

.portal-container #footer .links { background: #232323; padding: 20px 0px 10px 0px; text-align: center; color: #a6a6a6; }

.portal-container #footer .links .top { padding-bottom: 10px; margin-bottom: 0px; }

.portal-container #footer .links .top a { margin: 0px 10px; color: #fff; }

.portal-container #footer .links .copy { padding-top: 5px; }

.portal-container #footer .solial-media { width: 310px; margin: 0 auto; }

.portal-container #footer .solial-media ul { padding: 0px; overflow: hidden; }

.portal-container #footer .solial-media ul li { list-style: none; float: left; margin-right: 5px; }

.portal-container #footer .solial-media ul li a .fa-circle { color: #8b8b8b; opacity: 0.2; }

.portal-container #footer .solial-media ul li a .fa-twitter, .portal-container #footer .solial-media ul li a .fa-facebook { color: #232323; }

.portal-container #footer .solial-media ul li a:hover .fa-circle { opacity: 0.5; }

.portal-container #footer .solial-media ul li a:hover .fa-twitter, .portal-container #footer .solial-media ul li a:hover .fa-facebook { color: #fff; }

.portal-container #footer .solial-media .addFan { font-size: 11px; color: #8a8a8a; }

.portal-container #footer .solial-media iframe { width: 49px; height: 20px !important; margin: 10px 0 0 0px; }

.portal-container .title { font-size: 32px; }

.portal-container .lead { font-size: 18px; color: #5b5b5b; }

.portal-container .topLetters { padding: 10px 0; }

.portal-container .topLetters a { margin: 0 7px; }

.portal-container .mini_box { background: #FCFCFC; margin: 0 10px 20px 0; padding: 10px 10px; line-height: 24px; width: 310px; height: 150px; }

.portal-container .mini_box .network_name { border-bottom: 1px #eee solid; margin-bottom: 10px; }

.portal-container .mini_box .network_name a { color: #000; }

.portal-container .mini_box .network_name a:hover { text-decoration: none; }

.portal-container .mini_box .network_pic { text-align: center; }

.portal-container .testimonial_container { background-color: #FBFBFB; border: 1px solid #DDDDDD; display: block; margin-bottom: 18px; padding: 8px; text-align: left; color: #4f4f4f; }

.portal-container .testimonial_container footer { text-align: right; margin-top: 10px; color: #000; }

.portal-container .testimonial_container footer:before { content: '\00A0 \2014'; }

.portal-container #contentDiv { padding: 10px 5px; }

.portal-container .integration { margin-top: 40px; padding: 40px 0px 0px 0px; border-top: #c9c9c9 solid 1px; }

.portal-container .sign-logo { text-align: center; }

.portal-container .sign-logo a { display: inline-block; width: 219px; height: 72px; border: #c9c9c9 solid 1px; margin: 8px; }

.portal-container .sign-logo a a:hover { border-color: #19589b; }

.portal-container .payment-logo { margin: 20px 0px; }

.portal-container .payment-logo a { margin: 0px 5px; }

/*.portal-container .mobile-apps { background: url(/images/image_background_app.jpg?v=219) no-repeat center center; width: 100%; height: 889px; position: relative; overflow: hidden; }*/

.portal-container .mobile-apps p { margin-bottom: 0px; }

.portal-container .ma-main { width: 1000px; margin: 0 auto; padding-top: 124px; position: relative; height: 889px; }

.portal-container .ma-title { font-size: 52px; color: #fff; font-weight: 100; }

.portal-container .ma-txt { font-size: 36px; color: #fff; font-weight: 100; }

.portal-container .ma-down-txt { font-size: 24px; color: #fff; font-weight: 100; margin-top: 20px; }

.portal-container .ma-sms-language { overflow: hidden; width: 500px; }

.portal-container .ma-sms-language p { color: #fff; margin-top: 10px; font-size: 10px; }

.portal-container .mobile-num { overflow: hidden; width: 448px; }

.portal-container .mobile-num-input { width: 287px; background: #fff; height: 62px; padding: 10px; }

.portal-container .mobile-num-input input { width: 100%; height: 42px; line-height: 42px; font-size: 28px; border: 0 none; }

.portal-container .mobile-num-action { width: 161px; background: #edc806; color: #fff; border: 0 none; height: 62px; }

.portal-container .mobile-version { margin-top: 20px; }

.portal-container .mobile-version a { margin-right: 10px; }

.portal-container .mobile-img { position: absolute; bottom: 0px; right: -100px; }

.portal-container .msg-container { width: 1000px; margin: 0 auto; margin-top: 10px; }

.portal-container .mobile-menu { display: none; min-height: 42px; background: rgba(255, 255, 255, 0.95); border-bottom: #eceeef solid 1px; }

.portal-container .mobile-menu .container-8 {position: relative;line-height: 35px;}

.portal-container .mobile-menu .mobile-logo img { margin: 5px auto 0 auto; height: 24px; }

.portal-container .mobile-menu .d-btn { position: absolute; right: 8px; top: -4px; line-height: 40px; }

.portal-container .mobile-menu .navbar-menu-btn { position: absolute; right: 12px; top: 4px; display: block; font-size: 24px; color: #ccc; }

.portal-container .mobile-menu .navbar-menu-btn:hover, .portal-container .mobile-menu .navbar-menu-btn:active, .portal-container .mobile-menu .navbar-menu-btn:visited { color: #333; text-decoration: none; }

.portal-container .mobile-navbar { display: none; position: relative; }

.portal-container .mobile-navbar:before, .portal-container .mobile-navbar:after { content: " "; display: table; }

.portal-container .mobile-navbar:after:before, .portal-container .mobile-navbar:after:after { content: " "; display: table; }

.portal-container .mobile-navbar:after:after { clear: both; }

.portal-container .mobile-navbar.menu--open { overflow-x: hidden; overflow-y: auto; position: fixed; display: block; top: 0; right: 0; z-index: 10001; width: 100%; bottom: 0; border-right: solid 1px #ccc; }

.portal-container .mobile-navbar.menu--open .mobile-top { background: #fff; border-bottom: solid 1px #eceeef; position: relative; padding: 9px 0px 8px 0; }

.portal-container .mobile-navbar.menu--open .mobile-top .mobile-logo img { margin: 0 auto; height: 24px; }

.portal-container .mobile-navbar.menu--open .mobile-bottom { width: 100%; background: #f7f7f9; padding-right: 10px; padding-left: 10px; }

.portal-container .mobile-navbar.menu--open .mobile-bottom .btn { border-width: 2px; display: block; width: 100%; margin: 8px 0; }

.portal-container .mobile-navbar.menu--open .close-icon { position: relative; display: inline-block; width: 100%; height: 100%; overflow: hidden; }

.portal-container .mobile-navbar.menu--open .close-icon:before, .portal-container .mobile-navbar.menu--open .close-icon:after { content: ''; position: absolute; height: 2px; width: 100%; top: 50%; left: 0; margin-top: -1px; background: #fff; }

.portal-container .mobile-navbar.menu--open .close-icon:before { -moz-transform: rotate(45deg); -o-transform: rotate(45deg); -ms-transform: rotate(45deg); -webkit-transform: rotate(45deg); transform: rotate(45deg); }

.portal-container .mobile-navbar.menu--open .close-icon:after { -moz-transform: rotate(-45deg); -o-transform: rotate(-45deg); -ms-transform: rotate(-45deg); -webkit-transform: rotate(-45deg); transform: rotate(-45deg); }

.portal-container .mobile-navbar .mobile-btn {width: 41px;height: 42px;background: #818a91;border: none;text-align: center;position: fixed;z-index: 100000;}

.portal-container .mobile-navbar .mobile-main { background: #f7f7f9; margin-left: 41px; padding-right: 0px; height: 100%; }

.portal-container .mobile-navbar .mobile-menu { margin: 0; padding: 0; background: #fff; }

.portal-container .mobile-navbar .mobile-menu a { color: #818a91; }

.portal-container .mobile-navbar .mobile-menu a:hover, .portal-container .mobile-navbar .mobile-menu a:focus { background: #f7f7f9; color: #333; text-decoration: none; }

.portal-container .mobile-navbar .mobile-menu li { list-style-type: none; padding: 8px 16px; font-size: 16px; border-bottom: #eceeef solid 1px; }

.portal-container .mobile-navbar .mobile-menu li.active { background: #f7f7f9; }

.portal-container .mobile-navbar .mobile-menu li.active a { color: #ed9118; }

.portal-container .mobile-navbar .mobile-menu li:hover { background: #f7f7f9; }

.portal-container .mobile-navbar .mobile-menu li i { float: right; font-size: 20px; color: #818a91; margin-top: 4px; }

.portal-container .mobile-wrapper.menu--open .banner-with-video { padding-top: 0rem; }

.portal-container .mobile-wrapper.menu--open .mobile-menu { position: relative; }

.portal-container .portal-top { width: 1016px; padding-left: 15px; padding-right: 15px; margin: 0 auto; }

/*.portal-container .portal-product-app { padding-top: 20px; background: url("/images/product-banner.jpg?v=219") no-repeat #f9f9f9; }*/

.portal-container .portal-product-app h2 { margin-top: 50px; color: #fff; }

/*.portal-container .portal-product-app .download-ios, .portal-container .portal-product-app .download-android { background: url("/images/btn-group.png?v=219") no-repeat; width: 143px; height: 47px; display: inline-block; margin-bottom: 10px; }*/

.portal-container .portal-product-app .download-ios { background-position: -1px -2px; margin-right: 10px; }

.portal-container .portal-product-app .download-android { background-position: -1px -53px; }

.portal-container .portal-product-box { padding: 50px; border-top: 1px solid #e6e6e6; }

.portal-container .portal-product-box h2 { margin-top: 40px; }

/*.portal-container .portal-partner .portal-partner-top { background: url("/images/partner-banner.jpg?v=219") no-repeat; background-size: 100% 100%; !*margin-top: 10px;*! padding: 40px 20px; color: #FFF; }*/

.portal-container .portal-partner .portal-partner-top h2 { margin-bottom: 60px; }

.portal-container .portal-partner .portal-partner-top .partner-box { border: 2px solid rgba(255, 255, 255, 0.3); padding: 0 10px 10px 10px; height: 180px; }

.portal-container .portal-partner .portal-partner-top .partner-box h3 { font-size: 18px; color: #ec902d; margin-bottom: 20px; text-align: center; }

.portal-container .portal-partner .portal-partner-top .partner-box .partner-content { position: relative; }

.portal-container .portal-partner .portal-partner-top .partner-box .partner-content .partner-image { position: absolute; left: 0; top: 0; width: 100%; }

.portal-container .portal-partner .portal-partner-top .partner-box .partner-content .partner-text { position: absolute; left: 0; top: 0; display: none; }

.portal-container .portal-partner .portal-partner-help { padding: 40px 20px; }

.portal-container .portal-partner .portal-partner-help .row { margin: 70px 0 20px 0; }

.portal-container .portal-partner .type { font-size: 18px; padding-top: 20px; }

.portal-container .portal-partner .portal-partner-flow { border-top: 1px solid #e6e6e6; padding: 40px 20px; }

.portal-container .portal-partner .portal-partner-flow .partner-btn .btn { background-color: #19589b !important; }

.portal-container .portal-partner .portal-partner-flow .col-xs-3 { position: relative; }

.portal-container .portal-partner .portal-partner-flow .col-xs-3 .arrow { position: absolute; right: -20px; top: 45px; }

/*.portal-container .portal-partner .partner-form-banner { background: url("/images/form-banner.jpg?v=219") no-repeat; height: 300px; color: #fff; }*/

.portal-container .portal-partner .partner-form { box-shadow: 0 0 32px rgba(0, 0, 0, 0.1); padding: 40px 60px; }

.portal-container .portal-partner .partner-form .btn { width: 160px; height: 42px; background-color: #19589b; }

.portal-container .partner-btn, .portal-container .pricing-btn { padding-top: 30px; }

.portal-container .partner-btn .btn, .portal-container .pricing-btn .btn { width: 160px; height: 42px; background-color: #ed9118; padding-top: 10px; }

.portal-container .pricing-btn .btn { background-color: #19589b; }

.portal-container .portal-contact-us .bottom { padding-top: 40px; padding-bottom: 40px; }

.portal-container .portal-pricing .pricing-banner { background: url("../../images/pricing-banner.jpg") no-repeat; padding: 40px 20px; color: #fff; }

.portal-container .portal-pricing .border-none { border-top: none !important; }

.portal-container .portal-pricing .pricing-table { box-shadow: 0 0 32px rgba(0, 0, 0, 0.1); }

.portal-container .portal-pricing .pricing-table h3 { color: #323648; }

.portal-container .portal-pricing .pricing-table td { padding: 20px 32px; text-align: center; border-left: 1px solid #ebebeb; color: #5b5e6d; line-height: 1.5; vertical-align: middle; }

.portal-container .portal-pricing .pricing-table td:first-child { border-left: 0; }

.portal-container .portal-pricing .pricing-table td .price { font-weight: 700; font-size: 24px; margin-top: 0; margin-bottom: 24px; }

.portal-container .portal-pricing .pricing-table td .end-block { display: block !important; margin-top: 10px; }

.portal-container .portal-pricing .pricing-table td .mini-sep { display: block; width: 35px; height: 2px; background-color: #19589b; margin: 0 auto 24px; }

.portal-container .portal-pricing .pricing-table td .valign-top { vertical-align: top; }

.portal-container .portal-pricing .pricing-table td .link-btn { color: #ed9118; text-decoration: 1px solid #ed9118; }

.portal-container .portal-pricing .pricing-table td .btn { width: 100%; max-width: 180px; height: 42px; padding-top: 10px; margin: auto; background-color: #ed9118; }

.portal-container .portal-pricing .pricing-table td.feature-sep { text-align: left; color: #323648; background: #f5f5f5; }

.portal-container .portal-pricing .pricing-table td .icon-check { color: #ed9118; }

.portal-container .portal-downloadandroid .downloadandroid-banner { background: url("../../images/pricing-banner.jpg?v=219") no-repeat; padding: 40px 20px; color: #fff; }

.portal-container .portal-downloadandroid .border-none { border-top: none !important; }

.portal-container .portal-downloadandroid .downloadandroid-description { padding: 80px 20px; }

.portal-container .portal-downloadandroid .downloadandroid-phone img { width: 85.15%; margin: 0 auto; display: block; margin-bottom: 3.4%; }

.portal-container .portal-downloadandroid #weixin-tip { position: fixed; left: 0; top: 0; background: rgba(0, 0, 0, 0.8); filter: alpha(opacity=80); width: 100%; height: 100%; z-index: 1050; display: none; }

.portal-container .portal-downloadandroid #weixin-tip p { text-align: center; margin-top: 10%; padding: 0 5%; position: relative; }

.portal-container .portal-downloadandroid #weixin-tip .close { color: #fff; padding: 5px; font: bold 20px/24px simsun; text-shadow: 0 1px 0 #ddd; position: absolute; top: 0; left: 5%; }

@media (max-width: 1033px) { .page-container, .container, .container-8, #footer { width: 1016px !important; }
  .portal-container #portalHead .navbar-fixed-top li a { font-size: 12px; }
  .portal-container #portalHead .navbar-fixed-top .navbar-nav .nav-item + .nav-item { margin-left: 0px; } }

@media (min-width: 1032px) { .container, .container-8 { width: 1016px !important; }
  .text-lg-left { text-align: left; }
  .text-lg-right { text-align: right; }
  .text-lg-center { text-align: center; } }

@media (max-width: 992px) { #footer { width: auto !important; }}

@media (min-width: 768px) {
  .navbar-right {
    float: right!important;
    margin-right: -15px;
  }
  .navbar-nav {
      float: left;
      margin: 0;
  }
  .navbar-nav {
      margin: 7.5px -15px;
  }
  .nav {
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
  }
  #footer { width: auto !important; }
}

  @media (max-width: 633px) { .page-container, .container, .container-8, #footer { width: auto !important; }}
